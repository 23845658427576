@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.inner {
  width: 90%;
  max-width: 700px;
  margin: auto;
}


.sr-only {
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}
