.list {
  padding-top: 1em;
  padding-bottom: 8em;

  h3 {
    font-size: 14px;
  }

  h3 span {
    margin: 0 0.6em;
    color: #aaa;
  }
}

.list__none {
  padding-top: 1em;
  color: #a7a7a7;
  text-transform: uppercase;
}

.list__item {
  border: 1px solid #eee;
  margin-bottom: 0.5em;
  border-radius: 8px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.done {
    background: #eee;
  }
}

.list__inner {
  position: relative;
}

.list__check {
  display: flex;
  position: relative;
  overflow: hidden;

  input[type="checkbox"] {
    transform: translateX(-500%);
    left: -400%;
    position: absolute;

    &:checked {
      ~ label {
        .checkbox {
          &__indicator {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }
      }
    }

    &:focus, &:active {
      ~ label {
        .checkbox {  
          border: 1px solid black;
        }
      }
    }
  }
}

.list__item label {
  position: relative;
  display: flex;
  width: 100%;
  padding: 1em 1em;
  align-items: baseline;
  user-select: none;
}

.checkbox {
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 12px;
  background: #eee;
  border-radius: 3px;
  position: relative;
  top: 3px;

  &__indicator {
    position: absolute;
    top: 30%; left: 60%;
    transition: all 200ms ease;
    opacity: 0;
    font-size: 22px;
    transform: translate(-50%, -60%) rotate(-45deg);
  }
}


.list__item:hover { box-shadow: 0px 0px 30px rgba(black, 0.1); }
.list__item:hover button { opacity: 1; }
.list__item button {
  border: none;
  box-shadow: none;
  font-size: 10px;
  background-color: transparent;
  position: absolute;
  color: #a4a4a4;
  cursor: pointer;
  top: 10px; right: 8px;
  // opacity: 0;
  outline-color: black;
  transition: opacity 300ms ease;
}