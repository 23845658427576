.add-to-do {
  position: fixed;
  bottom: 0;
  width: 90%;
  border-radius: 13px;
  max-width: 700px;
  margin-bottom: 1.5em;
  box-shadow: 0px 0px 40px rgba(black, 0.1);
  background: white;
  z-index: 6;

  form {
    margin: 0;
    display: flex;
    padding: 1em;
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 0px;
    font-size: 1em;
    border: none;
    outline: none;
    padding-left: 0.6em;
    box-sizing:content-box;

    &:focus {
      border: 1px solid black;
    }
  }

  button {
    box-shadow: none;
    border-radius: 0px;
    background-color: transparent;
    border: none;
    position: relative;
    cursor: pointer;
    outline-color: black;

    &:active {
      top: 1px;
    }
  }
}